'use client'
import { createContext, useState } from 'react'
import type { Dispatch, FC, ReactNode, SetStateAction } from 'react'

type TypeTransitionName = 'exit' | 'animate'
type TypeContext = {
  transitionName: TypeTransitionName,
  setTransitionName: Dispatch<SetStateAction<TypeTransitionName>>
}

export const PageTransitionContext = createContext<TypeContext | null>(null)

interface Props {
  children: ReactNode
}

export const PageTransitionProvider:FC<Props> = ({ children }) => {
  const [transitionName, setTransitionName] = useState<TypeTransitionName>(null)

  return (
    <PageTransitionContext.Provider value={{ transitionName, setTransitionName }}>
      {children}
    </PageTransitionContext.Provider>
  )
}
