import type { FC, ReactNode } from 'react'
import NextLink, { type LinkProps } from 'next/link'
import { usePageTransition } from '@/hooks/usePageTransition'
import { useRouter } from 'next/navigation'
import { PAGE_FADE_IN_DURATION, PAGE_FADE_OUT_DURATION } from './TransitionWrapper'

// type Props = {
//   // arialabel: string,
//   ariaDisabled?: boolean
//   children: ReactNode
//   className?: string
//   href: TypeHref
//   locale?: string
//   onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
//   /**
//   * @remarks
//   * @see https://nextjs.org/docs/app/api-reference/components/link#prefetch
//   */
//   prefetch?: null | boolean
//   /**
//    * Defaults to false. When true, next/link will replace the current history state instead of adding a new URL into the browser’s history stack.
//    */
//   replace?: boolean
//   /**
//    * Defaults to true. The default behavior of <Link> is to scroll to the top of a new route or to maintain the scroll position for backwards and forwards navigation. When false, next/link will not scroll to the top of the page after a navigation.
//   */
//   scroll?: boolean
//   title?: string
// }

interface Props extends LinkProps {
  href: string,
  className?: string
  children: ReactNode
  rel?: 'alternate' | 'author' | 'bookmark' | 'help' | 'license' | 'me' | 'next' | 'prev' | 'search' | 'tag'
}

function sleep(ms: number):Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const Link: FC<Props> = ({ href, rel, children, className, ...props }) => {
  const { setTransitionName } = usePageTransition()
  const { push } = useRouter()

  const onClick = async (e) => {
    e.preventDefault()

    // Fade out current page
    setTransitionName('exit')
    props.onClick && props.onClick(e)

    // Wait for fade out to finish
    await sleep(PAGE_FADE_OUT_DURATION * 1000)

    // Navigate to new page
    push(href)

    // Fade in new page
    await sleep((PAGE_FADE_IN_DURATION * 1000) + 200)
    setTransitionName(null)
  }

  return (
    <NextLink href={href} {...rel && { rel }} {...className && { className }} {...props} onClick={onClick}>
      {children}
    </NextLink>
  )
}
