import { getTransition } from '@/styles'
import { motion } from 'framer-motion'
import { usePathname } from 'next/navigation'
import { ReactNode } from 'react'
import styled from 'styled-components'
import { usePageTransition } from '@/hooks/usePageTransition'

interface TransitionWrapperProps {
  children: ReactNode
}

export const TransitionWrapper = ({ children }: TransitionWrapperProps) => {
  const pathname = usePathname()
  const { transitionName } = usePageTransition()

  return (
    <Div key={pathname} variants={variants} initial='initial' animate={transitionName ?? 'animate'} exit='exit'>
      {children}
    </Div>
  )
}

const Div = styled(motion.div)``




export const PAGE_FADE_OUT_DURATION = .2
export const PAGE_FADE_IN_DURATION = .6
const variants = {
  initial: {
    opacity: 0,
    transition: {
      duration: PAGE_FADE_OUT_DURATION,
    }
  },
  animate: {
    opacity: 1,
    transition: {
      ...getTransition(),
      duration: PAGE_FADE_IN_DURATION,
    }
  },
  exit: {
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: PAGE_FADE_OUT_DURATION
    }
  }
}
