'use client'
import { Link } from '@/components/Link'
import styled from 'styled-components'
import { mq, vw, getP75, getP40, getP30, getMargin20 } from '@/styles'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import { useVideoReady } from '@/context'
import { useEffect } from 'react'

export const Excavator404 = () => {
  const { setVideoReady } = useVideoReady()

  useEffect(() => {
    setVideoReady(true)
  }, [])


  return (
    <Div>
      <Main>
        <AnimatePresence>
          <motion.div variants={variants} initial='initial' animate='animate'>
            <motion.figure variants={figureVariants} initial='initial' animate='animate'>
              <img src='/images/404/excavadora.png' height='370' width='450'></img>
            </motion.figure>
            <hgroup>
              <h1>404</h1>
              <h2>
                Poof! <br />
                It&apos;s gone
              </h2>
              <p>
                This page is no longer here.<br />
                Or maybe you just need to refresh? <br />
                In any case you will be always welcome <Link href={'/'}>home</Link>.
              </p>
            </hgroup>
          </motion.div>
        </AnimatePresence>
      </Main>
    </Div>
  )
}

const Div = styled.div`
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - ${vw(60 + 100, 'mobile')});
  overflow: hidden;
  position: relative;

  ${mq.greaterThan('tablet')} {
    min-height: calc(100vh - ${vw(100 + 100, 'desktop')});
  }
`

const Main = styled.div`
  align-items: end;
  display: flex;
  flex-direction: row;
  padding: 0 0 ${vw(40, 'mobile')};
  width: 100vw;

  ${mq.greaterThan('tablet')} {
    padding: 0 0 ${vw(40, 'desktop')} 0;
  }

  ${mq.greaterThan('desktop-wide')} {
    padding: 0;
  }

  > div {
    align-items: end;
    display: flex;
    flex-direction: row;
  }


  h1 {
    ${getP40()}

    ${mq.greaterThan('desktop')} {
      font-size: 40px;
      line-height: 48px;
    }
  }

  h1, h2, p {
    text-align: left;
  }

  h2 {
    ${getMargin20()}
    ${getP75()}

    ${mq.greaterThan('desktop')} {
      font-size: 75px;
      line-height: 75px;
      margin-bottom: 20px;
    }
  }

  p {
    ${getP30()}

    ${mq.greaterThan('desktop')} {
      font-size: 30px;
      line-height: 40px;
    }
  }

  a {
    text-decoration: underline;
  }

  figure {
    background-color: var(--color-background);
    height: 293px;

    ${mq.greaterThan('tablet')} {
      height: 370px;
    }

    img {
      -webkit-backface-visibility: visible;
      backface-visibility: visible;
      height: 100%;
      mix-blend-mode: multiply;
      object-fit: contain;
      position: relative;
      width: auto;
      z-index: 2;
    }
  }
`


const variants:Variants = {
  initial: {
    x: '100vw',
  },
  animate: {
    x: '0vw',
    transition: {
      duration: 3,
      delay: .5
    }
  }
}

const figureVariants:Variants = {
  initial: {
    y: '2px',
    x: 0,
  },
  animate: {
    y: '0px',
    x: '-100vw',
    transition: {
      y: {
        duration: .2,
        bounce: 0.25,
        repeat: Infinity,
        ease: 'linear'
      },
      x: {
        duration: 5,
        delay: 4
      }
    }
  }
}
